import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from '../../components/_shared/PageHeader';
import Img from "gatsby-image"

const TilePosts = ({ miniProjects }) => {
  return (
    <div className="grid items-center grid-cols-1 mx-auto gap-y-4 justify-items-stretch">
      {miniProjects.map(({ node }) => {
        return (
          <Link key={node.id} to={node.frontmatter.path} className="grid gap-x-4 hover:bg-gray-100 md:grid-cols-40rem40rem items-center md:justify-center text-indigo-600 hover:text-pink-600">
            <div className="tile_featuredImage justify-self-stretch">
              {node.frontmatter.featuredImage && <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />}
            </div>
            <div className="justify-self-center md:justify-self-end text-center md:text-right md:row-start-1 md:col-start-1">
              <h3 className="text-3xl">{node.frontmatter.title}</h3>
              <time className="text-sm">{node.frontmatter.date}</time>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

const bookerPrizeWinnersPage = {
  node: {
    id: 'bookerPrizeWinners',
    frontmatter: {
      path: '/mini-projects/booker-prize-winners',
      title: 'Booker Prize Winners'
    }
  }
}

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Moon Reacher" />
      <article>
        <h1 className="text-center my-4">Mini Projects</h1>
        <TilePosts miniProjects={[...data.miniProjects.edges, bookerPrizeWinnersPage]} />
      </article>
    </Layout>
  );
}

export default IndexPage

export const pageQuery = graphql`
{
  miniProjects: allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {type: {eq: "mini-project"}}}) {
    edges {
      node {
        id
        frontmatter {
          miniProject
          title
          path
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 736) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`;
